import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class CloudServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '760px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.cloudServices) }}>Cloud Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Cloud Services</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            As cloud environments continue to grow more complex, we need to offer the right mix of services to meet users’ expectations, now and in the future. Kanad strives to keep pace with this evolution and offers the following services –
						</p>
                        <div class="row" >
                            <div class="container" style={{ marginTop: 20 }}>
                                <ul class="unordered-list" >
                                    <li>Cloud managed services</li>
                                    <li>Cloud advisory services</li>
                                    <li>Cloud adoption services</li>
                                    <li>Cloud migration services</li>
                                    <li>Cloud business solutions</li>
                                    <li>Cloud security services</li>
                                    <li>Identity and access management</li>
                                    <li>Incident response</li>
                                    <li>Security strategy, risk and compliance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default CloudServices;