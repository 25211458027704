import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps";
import { config } from './config';
const { compose, withProps } = require("recompose");

const defaultMapOptions = {
    fullscreenControl: false,
};

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + config.keys.googleMap + "&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: config.data.lat, lng: config.data.lng }}
        defaultOptions={defaultMapOptions}
        disableDefaultUI={true}
    >
        {
            props.isMarkerShown &&
            <Marker position={{ lat: config.data.lat, lng: config.data.lng }} onClick={props.onMarkerClick}>
                <InfoWindow visible={true}>
                    <div>
                        <span style={{ fontWeight: 'bold', marginTop: '10px' }}>{config.data.address}</span><br />
                        {/* <span style={{ fontWeight: 'bold'}}>{config.data.location}</span><br /> */}
                        <span style={{ fontWeight: 'bold' }}>{config.data.city + ", " + config.data.state + ", " + config.data.pin}</span><br />
                        <span style={{ fontWeight: 'bold' }}>{config.data.country}</span><br />
                    </div>
                </InfoWindow>
            </Marker>
        }
    </GoogleMap>
)

class GoogleMaps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMarkerShown: true,
        }
    }
    componentDidMount() {
        this.delayedShowMarker()
    }

    delayedShowMarker() {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 0)
    }

    handleMarkerClick() {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
    }

    render() {
        return (
            <MyMapComponent
                isMarkerShown={this.state.isMarkerShown}
                onMarkerClick={() => this.handleMarkerClick()}
            />
        )
    }
}
export default GoogleMaps;