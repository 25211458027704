import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Lattice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Products</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Products</a>
                                    <a href="" onClick={() => { this.onClicked(pages.lattice) }}>Lattice</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Automatic Frequency/PSC/PCI Planning Solution</h3>
                        <div class="section-top-border">
                            <div class="row">
                                <div class="col-md-3">
                                    <img src="/kanadnetworks/img/products/kanadnetworks_lattice.png" alt="" class="img-fluid" />
                                </div>
                                <div class="col-md-9 mt-sm-20 left-align-p">
                                    <p >
                                        Kanad’s Lattice is an OSS data based automated Frequency/PSC/PCI/Neighbor-list  planning solution. Lattice helps achieve significant performance improvements in mobile networks. The key advantages of this solution is as below
						</p>
                                    <ul class="unordered-list">
                                        <li >Use of live OSS-based Measurement Reports to generate an Interference Matrix (IM) that precisely reflects the behavior of all subscribers across the network, thus ensuring an optimum plan weighted according to real traffic.</li>
                                        <li >Measurement-based techniques allow  to identify missing neighbors reported by actual mobiles; hence neighbor lists can be audited and corrected automatically.</li>
                                        <li >Lattice can identify cells that are causing excessive interference and due to antenna coverage overshooting. Such cases can be prioritized and forwarded to optimization teams for action.</li>
                                        <li >Lattice can simulate multiple frequency planning scenarios, band splits, hopping strategies and extensively evaluate their performance relative to the baseline. Thus the optimum frequency/PSC/PCI plan strategy can be chosen with full confidence.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p>
                            In a user-friendly three-step process, Lattice imports network data, generates interference matrix and deploys a suite of proprietary algorithms to deliver Frequency/PSC/PCI and adjecency optimization for below use cases.
						</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Periodic fine tuning of Frequency/PSC/PCI/Neighbor List plans to maintain optimum performance.</li>
                                        <li >Support for spectrum carving activities to accommodate a new technology.</li>
                                        <li >Site integrations, capacity expansions</li>
                                        <li >Frequency plan re-optimization after RF physical changes and/or modifications in traffic management strategies.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Lattice;

