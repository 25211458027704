import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class BusinessTransformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '920px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.businessDigitalTransformation) }}>Business and Digital Transformation</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Business and Digital Transformation</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Developing an effective digital transformation strategy is key to not only optimizing current business practices and performance but also to prepare organizations for future business needs and disruptions.
						</p>
                        <div class="row">
                            <div class="container" style={{marginTop: '20px'}}>
                                <h3 class="mb-20 title_color">Objectives</h3>
                                <h4>Engage customers with smarter experiences</h4>
                                <p>Experience a company provides is as important as its products or services. To meet rising customer expectations, you need to offer a unified experience.</p>
                            </div>
                            <div class="container">
                                <h4>Harness core business applications as the backbone of a smarter business</h4>
                                <p>Moving core business applications to cloud can help you drive innovation and become more responsive.</p>
                            </div>
                            <div class="container">
                                <h4>Build Smarter Supply Chains</h4>
                                <p>Building a resilient supply chain to manage volatility and deliver value to customers, even in the face of disruption.</p>
                            </div>
                            <div class="container">
                                <h4>Build resilient business operations with intelligence, insights and expertise</h4>
                                <p>Optimize asset performance and quickly adapt to changing circumstances with AI and IoT Data.</p>
                            </div>
                            <div class="container">
                                <h4>Rethink your workflows with AI and automation</h4>
                                <p>Rethink your workflows with AI and automation to deliver on digital transformation goals and build a smarter business.</p>
                            </div>
                            <div class="container">
                                <h4>Enable your workforce for resiliency</h4>
                                <p>Enhance employee engagement and productivity, reskill your workforce faster, and reimagine ways of working.</p>
                            </div>
                            <div class="container" style={{ marginTop: 30 }}>
                                <h3 class="mb-20 title_color">Our Offerings</h3>
                                <h4>Digital insights</h4>
                                <p>Digital insights driven business models are at the core of the customer-centric economy. However, identifying the possibilities hidden away in the tremendous amount of data is becoming increasingly difficult for organizations. As traditional analytical methods become obsolete, businesses need to reinvent the way they utilize data to glean actionable insights. Kanad helps you</p>
                                <ul class="unordered-list">
                                    <li >
                                        Leverage cutting-edge data analytics solutions
                                                </li>
                                    <li >
                                        Derive near real-time actionable insights
                                                </li>
                                    <li >
                                        Discover innovative solutions to business problems
                                                </li>
                                    <li >
                                        Drive informed decision making
                                                </li>
                                </ul>
                            </div>
                            <div class="container" style={{ marginTop: 30 }}>
                                <h4>Rapid Prototyping</h4>
                                <p>Competitive advantage lies in the ability to bring ideas to life at high speed, gather and incorporate feedback, and accelerate time to market to morph into the next-generation of delivery ecosystem. Kanad’s rapid prototyping offering enables organizations to:</p>
                                <ul class="unordered-list">
                                    <li >
                                        Accelerating idea-to-prototype process
                                                </li>
                                    <li >
                                        Garner quick feedback, adapt, and deploy solutions
                                                </li>
                                </ul>
                            </div>
                            <div class="container" style={{ marginTop: 30 }}>
                                <h4>Digital Experience</h4>
                                <p>Market dynamics are shifting at unprecedented rates, forcing organizations to respond to change with equal urgency. Technology has become central to digital businesses blurring the digital and physical worlds. Organizations must embrace these forces of digital proliferation to create connected digital experiences, or risk becoming extinct. We help organizations to:</p>
                                <ul class="unordered-list">
                                    <li >Create digitally enhanced omni-channel experiences.
                                </li>
                                    <li >Enhance customer understanding using capabilities like IoT, analytics, and mobility.
                                </li>
                                    <li >Customize offerings using predictive analytics and recommendation engines.
                                </li>
                                    <li >Optimize and enhance interactions throughout the customer lifecycle.
                                </li>
                                </ul>
                            </div>
                            <div class="container" style={{ marginTop: 30 }}>
                                <h4>Enabling Applications</h4>
                                <p>Our Services aim to empower organizations across the entire application life cycle. From design to building and managing the applications, our application services experts work with our clients to facilitate application-centric transformation. We help our customers to:</p>
                                <ul class="unordered-list">
                                    <li >Redesign enterprise architecture to accelerate digital transformation.
                                </li>
                                    <li >Transform content management strategy to deliver excellent contextual experiences.
                                </li>
                                    <li >Enhance omni-channel capabilities by leveraging latest technologies.
                                </li>
                                    <li >Design purpose built applications to address unique business challenges.
                                </li>
                                    <li >Embrace open source technologies to create new, reliable, robust, scalable, and economical applications faster.
                                </li>
                                </ul>
                            </div>
                            <div class="container" style={{ marginTop: 30 }}>
                                <h4>Legacy Re-engineering and Modernization</h4>
                                <p>One of the biggest challenges for companies today is to ensure technology and business process evolution are in sync so that systems, people, and processes are future-proof. Modernization delivers competitive advantages of agile business processes based on new technologies. It also helps mitigate risks and reduces the cost of ownership. We empower organizations to:</p>
                                <ul class="unordered-list">
                                    <li >Reengineer business processes and accelerate development cycle by enabling innovation.
                                </li>
                                    <li >Gain the resource base of modern skill sets.
                                </li>
                                    <li >Drive higher profit margins by improving operational efficiency.
                                </li>
                                    <li >Overcome design limitations and support new business requirements.
                                </li>
                                    <li >Build an easy to adopt, low cost, secure, and scalable solution.
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default BusinessTransformation;