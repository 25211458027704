const pages = {
	home: '/',
	contact: '/contact-us',
	about: '/about-us',
	
	//Products
	quark: '/product/quark',
	lattice: '/product/lattice',
	tracerDrive: '/product/tracer-drive',
	tracer: '/product/tracer',
	fission: '/product/fission',

	//Services
	networkPlanning: '/services/network-planning',
	networkOptimization: '/services/network-optimization',
	automaticFrequencyPlanning: '/services/automatic-frequency-psc-pci-planning',
	networkIntegration: '/services/network-installation-commissioning-configuration-integration',
	scftOptimization: '/services/scft-cluster-optimization',
	openRan: '/services/open-ran',
	fiveGServices: '/services/5g-services',
	spectrumRefarmingServices: '/services/spectrum-refarming-services',
	rfSurvey: '/services/rf-survey',
	emfSurvey: '/services/emf-survey',

	//Digital Services
	softwareProductEngineering: '/digital-services/software-product-engineering',
	businessDigitalTransformation: '/digital-services/business-digital-transformation',
	dataEngineering: '/digital-services/data-engineering',
	analytics:'/digital-services/analytics',
	mobileTechnologies:'/digital-services/mobile-technologies',
	cloudServices:'/digital-services/cloud-services',
	blockchainServices:'/digital-services/blockchain-development-services',
	experienceDesign:'/digital-services/experince-design',
	teamConsulting:'/digital-services/team-consulting',

	//Industries
	eCommerce:'/industries/e-commerce',
	education:'/industries/education',
	finance:'/industries/finance',
	manufacturing:'/industries/manufacturing',
	realEstate:'/industries/real-estate',

	//Liquor
	liquorDetails:'/liquor/services',

	all: '*',
	notFound: '/404',
	error: '/error-occured'
}

const postUrl = {
	contactUsEmail: '/contact-us/email'	
}

const getUrl = {
}

export { pages, postUrl, getUrl }; 