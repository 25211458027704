import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class TracerDrive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Products</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Products</a>
                                    <a href="" onClick={() => { this.onClicked(pages.tracerDrive) }}>Tracer Drive</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Drive Test based Analysis and Troubleshooting Tool</h3>
                        <div class="section-top-border">
                            <div class="row">
                                <div class="col-md-3">
                                    <img src="/kanadnetworks/img/products/kanadnetworks_tracerdrive.png" alt="" class="img-fluid" />
                                </div>
                                <div class="col-md-9 mt-sm-20 left-align-p">
                                    <p >
                                        Operators conduct drive testing on a regular basis using standard tools to check coverage and quality of their networks. Such exercises usually result in massive amounts of test data, which if analyzed carefully and correctly can help significantly in improving the network performance. However, due to limited availability of effectively trained resources and/or time, the operators fail to exploit the full opportunity of these efforts.
						</p>
                                    <p >
                                        Tracer Drive is a multi-technology automated solution that conducts a focused analysis of the drive test data to identify the problem areas and helps the optimization engineer in conducting the troubleshooting quickly and effectively.
						</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p>Tracer Drive is available for GSM, UMTS and LTE networks</p>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="container">
                                <h3 class="mb-20 title_color">Areas of Focus</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>No Dominance Areas</li>
                                        <li>Overshooting Cells</li>
                                        <li>Sector Swap</li>
                                        <li>Missing Neighbors</li>
                                        <li>Antenna Orientation Issues</li>
                                        <li>Poor Coverage</li>
                                        <li>IBS Spillage</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="container" style={{marginTop:'20px'}}>
                                <h3 class="mb-20 title_color">Benefits</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Minimizes the dependence on the competence level of the optimization engineer</li>
                                        <li>Helps exctract the maximum benefits of drive testing efforts</li>
                                        <li>Helps enhance the network performance
											<ul class="unordered-list">
                                                <li>Reduction in Poor Coverage areas
												</li>
                                                <li>
                                                    Reduction in Handover Failures
												</li>
                                                <li>Reduction in Call Drops</li>
                                                <li>Improvement in Voice Quality (DL)</li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TracerDrive;

