import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class EMFSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.networkOptimization) }}>EMF Survey</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">EMF Survey</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad helps its customers meet their EMF Survey/Measurement needs with an impeccable service standard.
						</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li><p>Methods</p>
                                            <ul class="unordered-list">
                                                <li>EMF Measurement</li>
                                                <li>EMF Calculations</li>
                                            </ul>
                                        </li>
                                        <li><p>Salient Features</p>
                                            <ul class="unordered-list">
                                                <li>On time delivery</li>
                                                <li>Online Expert Advise</li>
                                                <li>Accurate Survey Reports</li>
                                                <li>Effective Project Management</li>
                                                <li>Automated Survey Report Generation </li>
                                                <li>Extensive use of proprietary automated Work-force Management solution and Analytics to achieve efficiency, quality control and cost reduction</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default EMFSurvey;