import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';
import ImageSlider from './imageSlider';

class LiquorDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '880px' }}>
                                <h2>Online Liquor Delivery Platform (LoL)</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a href="" onClick={() => { this.onClicked(pages.liquorDetails) }}>LoL</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Online Liquor Delivery Platform (LoL)</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad’s Liquor On-line  (LoL) is a comprehensive online delivery platform specifically designed for alcoholic beverages. This ready-to-use platform is offered to the government agencies as well as  private establishments.
						</p>
                        <section class="mission_area">
                            <div class="row m0" id="at-liquor">
                                <div class="col-lg-5 p0">
                                    <div class="left_img"><div class="row" style={{ marginTop: 30 }}>
                                        <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                            <h3 class="mb-20 title_color">Components</h3>
                                            <div class="">
                                                <ul class="unordered-list">
                                                    <li>User Mobile App (iOS/Android)</li>
                                                    <li>Delivery App  (Android)</li>
                                                    <li>Business Module</li>
                                                    <li>Backend System</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="row" style={{ marginTop: 30 }}>
                                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                                <h3 class="mb-20 title_color">Features</h3>
                                                <div class="">
                                                    <ul class="unordered-list">
                                                        <li>User login  and age verification</li>
                                                        <li>Beverage-wise browsing</li>
                                                        <li>Store-wise browsing</li>
                                                        <li>Ordering system</li>
                                                        <li>Payment Gateway</li>
                                                        <li>Delivery system</li>
                                                        <li>Notifications/Alerts</li>
                                                        <li>Returns &  Refunds</li>
                                                        <li>Reviews & Feedback</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 p0" style={{ marginTop: 30 }}>
                                    <ImageSlider></ImageSlider>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        );
    }
}

export default LiquorDetails;