import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Fission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Products</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Products</a>
                                    <a href="" onClick={() => { this.onClicked(pages.fission) }}>Fission</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Spectrum Refarming Solution</h3>
                        <div class="section-top-border">
                            <div class="row">
                                <div class="col-md-3">
                                    <img src="/kanadnetworks/img/products/kanadnetworks_fission.png" alt="" class="img-fluid" />
                                </div>
                                <div class="col-md-9 mt-sm-20 left-align-p">
                                    <p >
                                        Kanad’s Fission is a Versatile Platform specifically built to address Spectrum Refarming Challenges Fission helps achieve a smooth transition to a changed spectrum scenario. Based on CM, PM and Traffic Recording Data, this solution offers optimal Spectral Efficiency while minimizing the impact on customer perceived quality.
						</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p>
                            It takes care of all Stages of a Spectrum Refarming, including -
						</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Traffic Recording based Heat map generation</li>
                                        <li >Feasibility Study/Scenario Analysis</li>
                                        <li >Network Dimensioning/TRX Dimensioning </li>
                                        <li >Sites Addition/Deletion</li>
                                        <li >Frequency/Neighbor Planning</li>
                                        <li >Post Refarming Parametric Optimization including Traffic Balancing/Offloading</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p style={{marginTop: 20}}>Fission is equally effective in addressing Network Consolidation project requirements as well.</p>
                    </div>
                </section>
            </div>
        );
    }
}

export default Fission;

