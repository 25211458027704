import React, { Component } from 'react'
import ItemsCarousel from 'react-items-carousel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

export default class ImageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }} id="liquor-ItemsCarousel">
                <ItemsCarousel
                    requestToChangeActive={(v) => {
                        this.setState({ index: v })
                    }}
                    activeItemIndex={this.state.index}
                    numberOfCards={1}
                    gutter={20}
                    infiniteLoop={true}
                    disableSwipe={false}
                    slidesToScroll={1}
                    
                    leftChevron={<IconButton size='small' aria-label="add"><ArrowBackIosIcon /></IconButton>}
                    rightChevron={<IconButton size='small' aria-label="add"><ArrowForwardIosIcon /></IconButton>}
                    outsideChevron
                    chevronWidth={40}
                >
                    {
                        images.map(val => {
                            return (
                                <div
                                    style={{ cursor: 'pointer', textAlign: 'center' }}>
                                   <img src={val.src} alt="" class="img-fluid" style={{height:'500px', width:'300px'}} />
                                </div>
                            )
                        })
                    }
                </ItemsCarousel>
            </div>
        )
    }
}

const images = [
    {src: "/kanadnetworks/img/liquor/home.png"},
    {src: "/kanadnetworks/img/liquor/listing.png"},
    {src: "/kanadnetworks/img/liquor/detail.png"},
    {src: "/kanadnetworks/img/liquor/cart.png"},
    {src: "/kanadnetworks/img/liquor/store.png"}];