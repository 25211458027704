import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class ECommerce extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	onClicked(page) {
		browserHistory.push({
			pathname: page
		})
	}

	componentDidMount() {
		$('#loader').css({
			display: 'none'
		})
	}

	render() {
		return (
			<div id="at-products-detail">
				<section class="banner_area">
					<div class="banner_inner d-flex align-items-center">
						<div class="container">
							<div class="banner_content">
								<h2>Industries</h2>
								<div class="page_link">
									<a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
									<a>Industries</a>
									<a href="" onClick={() => { this.onClicked(pages.eCommerce) }}>eCommerce</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class="whole-wrap">
					<div class="container">
						<h3 class="text-heading title_color">eCommerce</h3>
					</div>
				</div>
				<section class="sample-text-area">
					<div class="container">
						<p >
							Kanad offers efficient, cost effective and user-friendly eCommerce solutions for the following sectors –
						</p>
						<div class="row">
							<div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
								<div class="">
									<ul class="unordered-list">
										<li >Pharmaceutical as well as non-medical supplies in the hyper local segment </li>
										<li >Pathology labs Aggregator </li>
										<li >On-line Liquor Delivery</li>										
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default ECommerce;

