import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class BlockchainDevServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '900px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.cloudServices) }}>Blockchain development Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Blockchain development Services</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Blockchain is a distributed network commonly known as a shared, distributed ledger for recording transactions and tracking both tangible and intangible assets. Blockchain application development is quickly gaining traction across multiple industries.
						</p>
                        <p>
                            At Kanad, we understand how to implement blockchain technology across different industries. We build and deliver blockchain solutions that bring security and traceability into business operations and boost the business efficiency of our clients.
                        </p>
                        <p>
                            Whether you want to develop peer-to-peer decentralized applications, enterprise dApps or self-executing smart contracts, we help you deliver custom blockchain development services with our hands-on experience in blockchain technology.
                        </p>
                        <div class="row">
                            <div class="container" style={{marginTop:'20px'}}>
                                <h3 class="mb-20 title_color">Our Services</h3>
                                <h4>Blockchain Technology Consulting</h4>
                                <p>Our approach to blockchain technology consulting begins with what, why and how blockchain can benefit your enterprise solution with its capability to bring trust and transparency.</p>
                            </div>
                            <div class="container">
                                <h4>Blockchain based Supply Chain Development</h4>
                                <p>We can develop, deploy and manage supply chain solutions for various industries using blockchain technology, which offers complete transparency at every step of the product’s journey.</p>
                            </div>
                            <div class="container">
                                <h4>Smart Contracts</h4>
                                <p>Our  team can develop and deploy smart contracts for public and private blockchain networks. We have built smart contracts for crowdfunding, blockchain supply chain solutions and various dApps.</p>
                            </div>
                            <div class="container">
                                <h4>Wallet Development</h4>
                                <p>With blockchain skills and knowledge, our blockchain developers can develop wallet dApps which can hold a wide array of digital assets and currencies and offer the ability to check balance and track history.</p>
                            </div>
                            <div class="container">
                                <h4>dApps Development</h4>
                                <p>From ideation to design and development, our blockchain developers can build enterprise-grade decentralized applications (dApps) to help clients accelerate time to market and maximize ROI.</p>
                            </div>
                            <div class="container">
                                <h4>Custom Blockchain Development</h4>
                                <p>With expertise in multiple blockchain platforms, including Tezos, Hyperledger, Corda, Tron, Stellar and EOS, we build scalable and robust custom blockchain solutions for enterprises and startups.</p>
                            </div>
                            <div class="container">
                                <h4>Tokenization</h4>
                                <p>Tokenization can eliminate volatility and bring more liquidity to a wide array of assets. Therefore, our blockchain development team can help you tokenize any asset you want, ensuring trust, transparency and efficiency.</p>
                            </div>

                            <div class="container" style={{ marginTop: 20 }}>
                                <h3 class="mb-20 title_color">Platforms we work on</h3>
                                <ul class="unordered-list">
                                    <li>Hyperledger</li>
                                    <li>Ethereum</li>
                                    <li>Stellar</li>
                                    <li>Corda</li>
                                    <li>EOS</li>
                                    <li>Tron</li>
                                    <li>Hashgraph</li>
                                    <li>Tezos</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default BlockchainDevServices;