import React, { Component } from 'react'
import ItemsCarousel from 'react-items-carousel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

export default class DigitalServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }} id="digitalservices-ItemsCarousel">
                <ItemsCarousel
                    requestToChangeActive={(v) => {
                        this.setState({ index: v })
                    }}
                    activeItemIndex={this.state.index}
                    numberOfCards={1}
                    gutter={20}
                    infiniteLoop={true}
                    disableSwipe={false}
                    slidesToScroll={1}
                    leftChevron={<IconButton size='small' aria-label="add"><ArrowBackIosIcon /></IconButton>}
                    rightChevron={<IconButton size='small' aria-label="add"><ArrowForwardIosIcon /></IconButton>}
                    outsideChevron
                    chevronWidth={40}
                >
                    {
                        digitalServices.map(val => {
                            return (
                                <div class="mission_text" key={val.name}
                                    style={{ cursor: 'pointer', textAlign: 'center' }}>
                                    <h4>{val.name}</h4>
                                    <p>{val.desc}</p>

                                    <a class="banner_btn" style={{ marginTop: '30px' }} href="" onClick={() => { this.onClicked(val.page) }}>View more</a>
                                </div>
                            )
                        })
                    }
                </ItemsCarousel>
            </div>
        )
    }
}

const digitalServices = [
    { name: "Software Product Engineering", page: pages.softwareProductEngineering, type: '', src: "", desc: 'Efficiency, faster time to market, excellent customer experience and unhindered evolution are the toughest challenges of businesses. Our software product development services are designed to effectively meet these challenges faced by our customers and partners.' },
    { name: "Business and Digital Transformation", page: pages.businessDigitalTransformation, type: '', src: "", desc: 'Developing an effective digital transformation strategy is key to not only optimizing current business practices and performance but also to prepare organizations for future business needs and disruptions.' },

    { name: "Data Engineering", page: pages.dataEngineering, type: '', src: "", desc: 'Aligning Data and Artificial Intelligence strategies with business objectives, irrespective of data volumes, variety and velocity. ' },
    { name: "Analytics", page: pages.analytics, type: '', src: "", desc: 'Kanad helps in addressing your greatest challenges with solutions that unlock the potential in data. Leveraging the transformative power of analytics, we can deliver insights that change how you make decisions,, re-engineer or automate business processes, and effectively empower you to capture growth.' },
    { name: "Mobile Technologies", page: pages.mobileTechnologies, type: '', src: "", desc: 'Today, there is a real need to drive a mobile strategy that enables and empowers the business to be more efficient and productive. Whether the enterprise is jumping on the mobility bandwagon, has its own app store, or is looking toward the future of mobile application development, the need of the hour is to partner with a strategic IT player to keep pace with the speed of innovation in mobile today.' },
    { name: "Cloud Managed Services", page: pages.cloudServices, type: '', src: "", desc: 'As cloud environments continue to grow more complex, we need to offer the right mix of services to meet users’ expectations, now and in the future. Kanad strives to keep pace with this evolution' },

    { name: "Blockchain development Services", page: pages.blockchainServices, type: '', src: "", desc: 'Blockchain is a distributed network commonly known as a shared, distributed ledger for recording transactions and tracking both tangible and intangible assets. Blockchain application development is quickly gaining traction across multiple industries.' },
    { name: "Experience Design", page: pages.experienceDesign, type: '', src: "", desc: 'Our experienced architects and visual interface designers leverage the best practices in UX research and design principles. We build captivating user experiences by early validations and keeping users at the center.' },
    { name: "Team Augmentation & Consulting", page: pages.teamConsulting, type: '', src: "", desc: 'Kanad offers development firepower and implementation best practices that help you focus on growing and managing your business while we expand your technology offerings and/or improve your processes' }];