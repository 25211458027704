import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Finance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Industries</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Industries</a>
                                    <a href="" onClick={() => { this.onClicked(pages.finance) }}>Finance</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Finance</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad offers a powerful, yet lightweight High Frequency Trading Platform. The offering is available to our customers as a product as well as a managed service. The platform covers the entire range required for algorithmic trading -
                        </p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Make Trading Decisions
                                        <ul class="unordered-list">
                                                <li>Get market data  </li>
                                                <li>Define trading strategy </li>
                                                <li>Analyze securities against trading strategy </li>
                                            </ul>
                                        </li>
                                        <li >Create Trading Orders
                                        <ul class="unordered-list">
                                                <li>Get trade information </li>
                                                <li>Create trading order </li>
                                            </ul>
                                        </li>
                                        <li >Manage Orders
                                        <ul class="unordered-list">
                                                <li>Manage pending orders </li>
                                                <li>Route / submit orders </li>
                                                <li>Manage submitted orders </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Finance;

