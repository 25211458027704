import React from 'react';
import { browserHistory } from 'react-router';
import { pages } from '../../constants/url';
import Products from './products';
import Services from './services';
import DigitalServices from './digitalServices';
import Industries from './industries';
import Team from './team';
import Banner from './banner';
import $ from 'jquery';
import PreLoader from '../../components/PreLoader';

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		$('#loader').css({
            display: 'none'
        })
		$('#loader2').css({
			visibility: 'hidden'
		})
	}

	handleAboutClick() {
		browserHistory.push(pages.about);
	}
	handleActivatedCarbonClick() {
		browserHistory.push(pages.aboutActivatedCarbons);
	}
	handleWPACClick() {
		browserHistory.push(pages.workingPhenomenon);
	}
	handleMPClick() {
		browserHistory.push(pages.manufacturingProcess);
	}
	handleAUClick() {
		browserHistory.push(pages.applicationsUses);
	}

	render() {
		return (
			<div>
				<PreLoader></PreLoader>
				<Banner></Banner>
				<section class="success_area">
					<div class="row m0 right_dir" id="at-digitalservices">
						<div class="col-lg-6 p0">
							<div class="success_img">
								<img src="/kanadnetworks/img/other/KanadNetworks_Digital-Services.jpg" alt="" />
							</div>
						</div>
						<div class="col-lg-6 p0" >
							<h4 style={{ marginLeft: '20px' }} >Digital Services</h4>
							<p style={{ marginLeft: '20px' }}>Deep understanding and extensive experience in a diverse range of technologies places Kanad optimally to offer a range of Digital Services to its partners  and customers</p>
							<DigitalServices></DigitalServices>
						</div>
					</div>
				</section>
				<section class="mission_area">
					<div class="row m0" id="at-products">
						<div class="col-lg-6 p0">
							<div >
								<h4 style={{ marginLeft: '20px' }} >Products and Solutions</h4>
								<p style={{ marginLeft: '20px' }}>Bringing a Fresh initiative to mobile network planning and optimization challenge!</p>
								<p style={{ marginLeft: '20px' }}>A comprehensive suite of proprietary products and solutions enables us to meet your network planning and optimization needs in a unique way</p>
								<Products></Products>
							</div>
						</div>
						<div class="col-lg-6 p0">
							<div class="left_img"><img src="/kanadnetworks/img/other/KanadNetworks_Telecom.jpg" alt="" style={{ width: '100%' }} /></div>
						</div>
					</div>
				</section>
				<section class="success_area">
					<div class="row m0" id="at-services">
						<div class="col-lg-6 p0">
							<div class="success_img">
								<img src="/kanadnetworks/img/other/KanadNetworks_Telecom-Services.jpg" alt="" />
							</div>
						</div>
						<div class="col-lg-6 p0" >
							<h4 style={{ marginLeft: '20px' }} >Services</h4>
							<p style={{ marginLeft: '20px' }}>Mobile service providers in mature markets seek to differentiate their services through quality, and to lower the cost of churn. Carriers in early-stage markets must support subscriber growth by increasing network capacity.</p>
							<Services></Services>
						</div>
					</div>
				</section>
				<Industries></Industries>
				{/* <section class="team_area" id="at-team">
					<Team></Team>
				</section> */}
			</div>
		)
	}
}

export default Home;


