import React from 'react';
import { browserHistory } from 'react-router';
import { pages } from '../../constants/url';
import $ from 'jquery';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: ''
		}
		this.handleAboutClick = this.handleAboutClick.bind(this);
	}

	componentDidMount() {
		$(window).scroll(function () {
			const headerDiv = $('#at-header');
			const imgLogo = $('#imgLogo');

			var currentPos = $(window).scrollTop();
			if ($(window).width() > 974) {
				if (currentPos >= 100) {
					headerDiv.addClass("header_area navbar_fixed");
					headerDiv.css({
						transition: 'all 0.4s ease'
					})
					imgLogo.css({
						width: '100%'
					})
					imgLogo.attr("src", "/kanadnetworks/img/KanadNetworks135x45.png");
				}
				else {
					headerDiv.removeClass("header_area navbar_fixed");
					headerDiv.addClass("header_area");
					imgLogo.attr("src", "/kanadnetworks/img/KanadNetworks273x91.png");
					imgLogo.css({
						width: '87%'
					})
				}
			}
			else {
				if (currentPos >= 100) {
					headerDiv.addClass("header_area navbar_fixed");
					headerDiv.css({
						transition: 'all 0.4s ease'
					})
					imgLogo.css({
						width: '100%'
					})
					imgLogo.attr("src", "/kanadnetworks/img/KanadNetworks135x45.png");
				}
				else {
					headerDiv.removeClass("header_area navbar_fixed");
					headerDiv.addClass("header_area");
					imgLogo.attr("src", "/kanadnetworks/img/KanadNetworks273x91.png");
					imgLogo.css({
						width: '87%'
					})
				}
			}
		})
	}

	handleHomeClick() {
		browserHistory.push(pages.home);
		this.setState({
			selected: 'home'
		})
	}

	handleAboutClick() {
		browserHistory.push(pages.about);
		this.setState({
			selected: 'about'
		})
	}

	handleContactClick() {
		browserHistory.push(pages.contact);
		this.setState({
			selected: 'contact'
		})
	}
	onClicked(page) {
		browserHistory.push({
			pathname: page
		})
	}

	render() {
		return (
			<header className="header_area" id="at-header">
				<div className="main_menu">
					<nav className="navbar navbar-expand-lg navbar-light">
						<div className="container box_1620">
							<a className="navbar-brand logo_h" href="" onClick={this.handleHomeClick}>
								<img id="imgLogo" style={{ pointer: 'cursor', width: '87%' }} src="/kanadnetworks/img/KanadNetworks273x91.png" alt="" />
							</a>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<div className="collapse navbar-collapse offset" id="navbarSupportedContent">
								<ul className="nav navbar-nav menu_nav justify-content-center">
									<li className={this.state.selected == "home" ? "nav-item active" : "nav-item"} id="li-home"><a className="nav-link" href="" onClick={this.handleHomeClick}>Home</a></li>
									<li className={this.state.selected == "about" ? "nav-item active" : "nav-item"} id="li-about"><a className="nav-link" href="" onClick={this.handleAboutClick}>About Us</a></li>
									<li className="nav-item submenu dropdown" id="telecom">
										<a href="" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Telecommunication</a>
										<ul className="dropdown-menu">
											<li className="nav-item" id="menu-products">
												<a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="">Products</a>
												<ul className="dropdown-menu">
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.quark) }}>Quark</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.lattice) }}>Lattice</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.tracer) }}>Tracer</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.tracerDrive) }}>Tracer Drive</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.fission) }}>Fission</a>
													</li>
												</ul>
											</li>
											<li className="nav-item" id="menu-services">
												<a className="nav-link" href="#at-services">Services</a>
												<ul className="dropdown-menu">
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.networkPlanning) }}>Network Planning</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.networkOptimization) }}>Network Optimization</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.automaticFrequencyPlanning) }}>Automatic Frequency/PSC/PCI Planning</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.networkIntegration) }}>Network Installation, Commissioning, Configuration and Integration</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.scftOptimization) }}>SCFT/Cluster Optimization</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.rfSurvey) }}>RF Survey</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.emfSurvey) }}>EMF Survey</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.openRan) }}>OpenRAN</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.fiveGServices) }}>5G Services</a>
													</li>
													<li className="nav-item">
														<a className="nav-link" href="" onClick={() => { this.onClicked(pages.spectrumRefarmingServices) }}>Spectrum Refarming Services</a>
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li className="nav-item submenu dropdown">
										<a href="" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Industries</a>
										<ul className="dropdown-menu" style={{ minWidth: '360px' }}>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.education) }}>Education</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.finance) }}>Finance</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.realEstate) }}>Real Estate</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.manufacturing) }}>Manufacturing</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.eCommerce) }}>eCommerce</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.liquorDetails) }}>Online Liquor Delivery Platform (LoL)</a></li>
										</ul>
									</li>
									<li className="nav-item submenu dropdown">
										<a href="" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Digital Services</a>
										<ul className="dropdown-menu" style={{ minWidth: '340px' }}>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.softwareProductEngineering) }}>Software Product Engineering</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.businessDigitalTransformation) }}>Business and Digital Transformation</a></li>
											{/* <li className="nav-item"><a className="nav-link" href="#at-digitalservices">Mobility</a></li> */}
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.dataEngineering) }}>Data Engineering</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.analytics) }}>Analytics</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.mobileTechnologies) }}>Mobile Technologies</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.cloudServices) }}>Cloud Services</a></li>
											{/* <li className="nav-item"><a className="nav-link" href="#at-digitalservices">Video Conferencing services</a></li> */}
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.blockchainServices) }}>Blockchain development Services</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.experienceDesign) }}>Experience Design</a></li>
											<li className="nav-item"><a className="nav-link" href="" onClick={() => { this.onClicked(pages.teamConsulting) }}>Team Augmentation & Consulting</a></li>
										</ul>
									</li>
									<li className={this.state.selected == "contact" ? "nav-item active" : "nav-item"}><a className="nav-link" href="" onClick={this.handleContactClick}>Contact Us</a></li>
								</ul>
								{/* <ul className="nav navbar-nav navbar-right">
									<li className="nav-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
									<li className="nav-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
									<li className="nav-item"><a href="#"><i className="fa fa-dribbble"></i></a></li>
									<li className="nav-item"><a href="#"><i className="fa fa-behance"></i></a></li>
									<li className="nav-item"><a href="#" className="search"><i className="lnr lnr-magnifier"></i></a></li>
								</ul> */}
							</div>
						</div>
					</nav>
				</div>
			</header>
		)
	}
}

export default Header;