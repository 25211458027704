import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class MobileTechnologies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '800px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.mobileTechnologies) }}>Mobile Technologies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Mobile Technologies</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Today, there is a real need to drive a mobile strategy that enables and empowers the business to be more efficient and productive. Whether the enterprise is jumping on the mobility bandwagon, has its own app store, or is looking toward the future of mobile application development, the need of the hour is to partner with a strategic IT player to keep pace with the speed of innovation in mobile today.
						</p>
                        <p>
                            The challenges around mobile business application and software development, system integration, information security, application deployment and device management are very real, but the rewards can be significant for organizations that are able to successfully engage a partner who could help them navigate these obstacles.
                        </p>
                        <p>
                            At Kanad, we work on the latest of the technologies to help our clients meet these challenges and improve their overall business utilizing the best of mobile technology. Our expert team develops both enterprise level as well as consumer targeted custom applications across mobile platforms and technologies. We have capabilities to provide the best and latest of these technologies for our clients.
                        </p>
                        <div class="row">
                            <div class="container">
                                <h3 class="mb-20 title_color">Key capabilities</h3>
                                <h4>Scalability</h4>
                                <p>Creating solutions that don’t scale across an enterprise can be costly in terms of development, management and maintenance. Apps need to be conceived holistically with consideration for lines of business, processes and technical environments.</p>
                            </div>
                            <div class="container">
                                <h4>Integration</h4>
                                <p>Applications offered on mobile phones and tablets have a separation between the mobile app and back-end business logic and data services. To connect logic and data services to the app is critical.</p>
                            </div>
                            <div class="container">
                                <h4>Cloud-based development</h4>
                                <p>The cloud offers an efficient platform to develop, test and manage applications. Developers can use application-programming interfaces (API) to connect apps to back-end data and focus on front-end functions.</p>
                            </div>
                            <div class="container">
                                <h4>Mobility management</h4>
                                <p>As mobile technology is deployed, organizations look to enterprise mobility management (EMM) solutions to configure devices and apps; track device usage and inventories; control and protect data; and support and troubleshoot issues.</p>
                            </div>
                            <div class="container">
                                <h4>Security</h4>
                                <p>The mobile security battle is daunting in terms of volume and complexity. Artificial Intelligence (AI) is emerging as a key weapon to discern security anomalies in vast amounts of data.</p>
                            </div>
                            <div class="container">
                                <h4>Edge computing</h4>
                                <p>From a business perspective, edge computing offers the opportunity to perform more comprehensive data analysis and gain deeper insights faster.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default MobileTechnologies;