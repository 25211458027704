import React, { Component } from 'react'
import ItemsCarousel from 'react-items-carousel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

export default class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        }
    }

    onCollectionClicked(displayName, dbName) {
        browserHistory.push({
            pathname: pages.search,
            search: '?t=cl&dn=' + dbName + '&q=' + displayName
            // state: { data: tmpData[0], selected: this.state.selected }
        })
    }

    render() {
        var card = 4;
        if (window.innerWidth <= 500)
            card = 1;
        else if (window.innerWidth <= 768)
            card = 3;
        return (
            <div style={{ textAlign: 'center' }} id="team-ItemsCarousel">
                <ItemsCarousel
                    requestToChangeActive={(v) => {
                        this.setState({ index: v })
                    }}
                    activeItemIndex={this.state.index}
                    autoPlay={true}
                    numberOfCards={card}
                    gutter={20}
                    infiniteLoop={true}
                    disableSwipe={false}
                    slidesToScroll={1}
                    leftChevron={<IconButton size='small' aria-label="add"><ArrowBackIosIcon /></IconButton>}
                    rightChevron={<IconButton size='small' aria-label="add"><ArrowForwardIosIcon /></IconButton>}
                    outsideChevron
                    chevronWidth={40}
                >
                    {
                        team.map(val => {
                            return (
                                <div class="item">
                                    <div class="team_item">
                                        <img style={{width:'322px', height:'400px'}} src={val.src} alt="" />
                                        <div class="hover_text">
                                            <a href="#"><h4>{val.name}</h4></a>
                                            <p>{val.position}</p>
                                            <ul class="list">
                                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </ItemsCarousel>
            </div>
        );
    }
}

const team = [
    { name: "Vijay Shukla", src: "/kanadnetworks/img/team/team.jpg", position: 'CEO & Founder' },
    { name: "Kirti Gupta", src: "/kanadnetworks/img/team/team.jpg", position: 'CTO & Founder' },
    { name: "Kirtinjay Sharma", src: "/kanadnetworks/img/team/team.jpg", position: 'CFO & Founder' },
    { name: "Varun Sharma", src: "/kanadnetworks/img/team/team.jpg", position: 'Product Head & Founder' }];