import React, { Component } from 'react'
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import Slider from "react-slick";

export default class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };

        return (
            <section class="home_banner_area" id="at-home-banner">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <Slider {...settings}>
                            {
                                banners.map(val => {
                                    return (<div class="swiper-slide"><img src={val.src} alt="" />
                                        <div class="slider_text_inner">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-7">
                                                        <div class="slider_text">
                                                            <h2>{val.name}</h2>
                                                            <p style={{fontSize: '16px'}}>{val.desc}</p>
                                                            {
                                                                val.page.length > 0 ?
                                                                    <a class="banner_btn" href="" onClick={() => { this.onClicked(val.page) }}>View more</a>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </section>
        )
    }
}

const banners = [
    { name: "Software Product Engineering", page: pages.softwareProductEngineering, src: "/kanadnetworks/img/slider/KanadNetworks-Main.jpg", desc: 'Best in class Software product development services across domains and technologies.' },
    { name: "Business and Digital Transformation", page: pages.businessDigitalTransformation, src: "/kanadnetworks/img/slider/KanadNetworks-Main1.jpg", desc: 'Optimize your business processes to improve efficiency and meet the challenges of tomorrow' },
    { name: "Data Engineering", page: pages.dataEngineering, src: "/kanadnetworks/img/slider/KanadNetworks-Main2.jpg", desc: 'Helping our partners unlock the full potential of data' },
    { name: "Analytics", page: pages.analytics, src: "/kanadnetworks/img/slider/KanadNetworks-Main.jpg", desc: 'Delivering deeper insights with transformative powers of analytics' },
    { name: "Mobile Technologies", page: pages.mobileTechnologies, src: "/kanadnetworks/img/slider/KanadNetworks-Main1.jpg", desc: 'Taking your business to Anytime, Anywhere regime with best and latest of mobility landscape' },
    { name: "Cloud Services", page: pages.cloudServices, src: "/kanadnetworks/img/slider/KanadNetworks-Main2.jpg", desc: 'Addressing your cloud needs: today and tomorrow' },
    { name: "Blockchain development Services", page: pages.blockchainServices, src: "/kanadnetworks/img/slider/KanadNetworks-Main.jpg", desc: 'Empowering your business with the boon of Blockchain' },
    { name: "Experience Design", page: pages.experienceDesign, src: "/kanadnetworks/img/slider/KanadNetworks-Main1.jpg", desc: 'Combining Human Touch and Intelligent Data to enhance Experiences' },
    { name: "Team Augmentation & Consulting", page: pages.teamConsulting, src: "/kanadnetworks/img/slider/KanadNetworks-Main2.jpg", desc: 'Bringing quality, cost-efficiency and flexibility to all your resource requirements' },
    { name: "Telecom Engineering", page: '', src: "/kanadnetworks/img/slider/KanadNetworks-Main1.jpg", desc: 'Bringing a fresh initiative to network planning, optimization, and management' },
    { name: "Online Liquor Delivery Platform (LoL)", page: pages.liquorDetails, src: "/kanadnetworks/img/slider/KanadNetworks-Main.jpg", desc: 'Kanad’s Liquor On-line (LoL) is a comprehensive online delivery platform specifically designed for alcoholic beverages. This ready-to-use platform is offered to the government agencies as well as  private establishments.' }];