import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	onClicked(page) {
		browserHistory.push({
			pathname: page
		})
	}	

	render() {

		return (
			<footer className="footer-area" id="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-3  col-md-6 col-sm-6">
							<div className="single-footer-widget">
								<a className="navbar-brand logo_h" onClick={this.handleHomeClick}><img src="/kanadnetworks/img/KanadNetworks273x91.png" alt="" /></a>
								<p style={{ fontWeight: 'bold', marginTop: 10 }}>Kanad Networks</p>
								<p style={{ fontWeight: 'bold', marginTop: 10 }}>…Crafting  a digital symphony!</p>
								<p style={{ marginTop: 10 }}>We are a technology start-up offering innovative products, solutions and services to a range of industries and domains. Kanad started off with venturing into telecom engineering and has gradually sprouted into various domains</p>

							</div>
						</div>
						<div className="col-lg-1 col-md-6 col-sm-6" >
							<div className="single-footer-widget" id="footer-home">
								<h6 className="footer_title">About</h6>
								<div className="row">
									<div className="col-4">
										<ul className="list">
											<li><a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.about) }}>About Us</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.contact) }}>Contact Us</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>					
						<div className="col-lg-2 col-md-6 col-sm-6" >
							<div className="single-footer-widget" id="products">
								<h6 className="footer_title">Products</h6>
								<div className="row">
									<div className="col-4">
										<ul className="list">
											<li><a href="" onClick={() => { this.onClicked(pages.quark) }}>Quark</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.lattice) }}>Lattice</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.tracer) }}>Tracer</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.tracerDrive) }}>Tracer Drive</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.fission) }}>Fission</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-sm-6" >
							<div className="single-footer-widget" id="services">
								<h6 className="footer_title">Services</h6>
								<div className="row">
									<div className="col-4">
										<ul className="list">
											<li><a href="" onClick={() => { this.onClicked(pages.networkPlanning) }}>Network Planning</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.networkOptimization) }}>Network Optimization</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.automaticFrequencyPlanning) }}>Automatic Frequency/PSC/PCI Planning</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.networkIntegration) }}>Network Installation, Commissioning, Configuration and Integration</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.scftOptimization) }}>SCFT/Cluster Optimization</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.rfSurvey) }}>RF Survey</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.emfSurvey) }}>EMF Survey</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.openRan) }}>OpenRAN</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.fiveGServices) }}>5G Services</a></li>
											<li><a href="" onClick={() => { this.onClicked(pages.spectrumRefarmingServices) }}>Spectrum Refarming Services</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div class="contact_info">
								<div className="info_item">
									<i className="lnr lnr-home"></i>
									<h6>Gurugram, Haryana, 122004, India</h6>
									<p>816, Vipul Business Park, Sohna Road, Sector 48</p>
								</div>
								<div class="info_item">
									<i class="lnr lnr-phone-handset"></i>
									<h6><a href="#">(0124) 4085 843</a></h6>
									<p>Mon to Fri 9 am to 6 pm</p>
								</div>
								<div class="info_item">
									<i class="lnr lnr-envelope"></i>
									<h6><a href="#">info@kanadnetworks.com</a></h6>
									<p>Send us your query anytime!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="border_line"></div>
				<div className="container">
					<div className="row footer-bottom d-flex justify-content-between align-items-center">
						<p className="col-lg-8 col-md-8 footer-text m-0">
							Copyright &copy;{new Date().getFullYear()} <a href="" target={"_blank"}>Kanad Networks Pvt Ltd</a>
						</p>
						{/* <div className="col-lg-4 col-md-4 footer-social">
							<a href="#"><i className="fa fa-facebook"></i></a>
							<a href="#"><i className="fa fa-twitter"></i></a>
							<a href="#"><i className="fa fa-dribbble"></i></a>
							<a href="#"><i className="fa fa-behance"></i></a>
						</div> */}
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;

