import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class FiveGServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.fiveGServices) }}>5G Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">5G Services</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">                       
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Key Focus on M2M/IoT using 3GPP NB-IoT.</li>
                                        <li >Implementing NB-IoT based Solutions</li>
                                        <li >NB-IoT Planning Services: Coverage Planning, Capacity & Link Budgets for Cat-M1 (eMTC) & Cat-NB1 (NB-IoT).</li>
                                        <li >Sensors & Application design, Implementation & Use Case Planning.</li>
                                        <li >Fixed Wireless Planning & Implementation:</li>
                                        <li >5G Architecture design & Implementation based on NFV/SDN vision.</li>
                                        <li >Planning & Implementation of Small Cell/HetNet based CRAN</li>
                                        <li >E2E Architecture design for a CRAN based Network including Front haul and Baseband Hoteling</li>
                                        <li >NR Nominal RF Planning capability</li>
                                        <li >Massive MIMO Planning</li>

                                        <li >mmWave Planning</li>
                                        <li >FWA (Fixed Wireless Access) Planning</li>
                                        <li >5G HetNet Analysis </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default FiveGServices;