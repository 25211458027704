import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Tracer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Products</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Products</a>
                                    <a href="" onClick={() => { this.onClicked(pages.tracer) }}>Tracer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Network Visualization Tool</h3>
                        <div class="section-top-border">
                            <div class="row">
                                <div class="col-md-3">
                                    <img src="/kanadnetworks/img/products/kanadnetworks_tracer.png" alt="" class="img-fluid" />
                                </div>
                                <div class="col-md-9 mt-sm-20 left-align-p">
                                    <p >
                                        Tracer is an immensely powerful network optimization, analytics and visualization tool that allows mobile operators to visualize, analyze and troubleshoot RF network performance problems, quickly and effectively. The target areas can range from the complete network to the individual cells in the network.
						</p>
                                    <p >
                                        Tracer intelligently collects, post-processes and analyzes data from network configuration database, multiple network performance data sources, including call traces, and drive test data. It also generates KPI reports based on all collected data points.
						</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p>The solution employs optimization best practices, integrated optimization algorithms and smart analysis capabilities that enables faster and efficient workflows to accurately identify root causes for a series of network issues. With the help of Tracer network engineers do not have to go through tedious and labor-intensive analysis processes to immediately detect the cause of poor performance.</p>
                        <p>This state-of-the-art optimization solution integrates seamlessly into daily operations and network expansion activities; the solution also ensures total flexibility to tailor the tool to specific mobile operators’ needs and optimization scenarios.</p>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="container">
                                <h3 class="mb-20 title_color">Features</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Tailor-made for Telecom GIS Needs</li>
                                        <li>Visual depiction of Network performance, configuration & Events </li>
                                        <li>Drill Down - from Network to Sector Level</li>
                                        <li>Supports Google Maps as well as custom Maps with Multiple Views</li>
                                        <li>Terrain/Elevation Information Display</li>
                                        <li>Neighbor List Audit & Tuning</li>
                                        <li>Support for Multiple Data Sources</li>
                                        <li>Customer Care Support</li>
                                        <li>Multi-Technology/Multi-layer/Multi-Vendor Support</li>
                                        <li>Powerful Analytics</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tracer;

