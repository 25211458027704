import React, { Component } from 'react'
import ItemsCarousel from 'react-items-carousel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

export default class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
   
    render() {
        return (
            <div style={{ textAlign: 'center' }} id="products-ItemsCarousel">
                <ItemsCarousel
                    requestToChangeActive={(v) => {
                        this.setState({ index: v })
                    }}
                    activeItemIndex={this.state.index}
                    numberOfCards={1}
                    gutter={20}
                    infiniteLoop={true}
                    disableSwipe={false}
                    slidesToScroll={1}
                    leftChevron={<IconButton size='small' aria-label="add"><ArrowBackIosIcon /></IconButton>}
                    rightChevron={<IconButton size='small' aria-label="add"><ArrowForwardIosIcon /></IconButton>}
                    outsideChevron
                    chevronWidth={40}
                >
                    {
                        products.map(val => {
                            return (
                                <div class="item" key={val.name} style={{ textAlign: "center", height: '100%' }} >
                                    <img src={val.src} />

                                    <div class="mission_text" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                        <h4>{val.type}</h4>
                                        <p>{val.desc}</p>
                                        <p>{val.desc2}</p>
                                        <a class="banner_btn" style={{ marginTop: '30px' }} href="" onClick={() => { this.onClicked(val.page) }}>View more</a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </ItemsCarousel>
            </div>
        )
    }
}

const products = [
    { name: "Quark", page: pages.quark, type: 'Parametric Optimization Tool', src: "/kanadnetworks/img/products/kanadnetworks_quark.png", desc: 'An OSS data-based, automated Cell Database Parameter optimization tool', desc2: 'In order to survive in an increasingly competitive environment, operators must focus on improving operational efficiency and minimizing capital expenditure, while at the same time delivering the highest quality end user experience.' },
    { name: "Lattice", page: pages.lattice, type: 'Automatic Frquency/PSC/PCI Planning Solution', src: "/kanadnetworks/img/products/kanadnetworks_lattice.png", desc: 'An OSS data-based, automaticFrquecny/PSC/PCI planning tool', desc2: 'Kanad’s Lattice is an OSS data based automated Frequency/PSC/PCI/Neighbor-list  planning solution. Lattice helps achieve significant performance improvements in mobile networks.' },
    { name: "Tracer", page: pages.tracer, type: 'Network Visualization Tool', src: "/kanadnetworks/img/products/kanadnetworks_tracer.png", desc: 'Drive Test based Automated Analysis and Troubleshooting tool', desc2: 'Tracer is an immensely powerful network optimization, analytics and visualization tool that allows mobile operators to visualize, analyze and troubleshoot RF network performance problems, quickly and effectively. The target areas can range from the complete network to the individual cells in the network.' },
    { name: "Tracer Drive", page: pages.tracerDrive, type: 'Drive Test based Analysis and Troubleshooting Tool', src: "/kanadnetworks/img/products/kanadnetworks_tracerdrive.png", desc: 'Network Optimization, Events, Configuration & Performance Visualization Tool', desc2: 'Tracer Drive is a multi-technology automated solution that conducts a focused analysis of the drive test data to identify the problem areas and helps the optimization engineer in conducting the troubleshooting quickly and effectively.' },
    { name: "Fission", page: pages.fission, type: 'Spectrum Refarming Solution', src: "/kanadnetworks/img/products/kanadnetworks_fission.png", desc: 'Spectrum Re-farming, Network dimesniong and merging solution', desc2: 'Kanad’s Fission is a Versatile Platform specifically built to address Spectrum Refarming Challenges Fission helps achieve a smooth transition to a changed spectrum scenario. Based on CM, PM and Traffic Recording Data, this solution offers optimal Spectral Efficiency while minimizing the impact on customer perceived quality.' }];