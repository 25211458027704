import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Education extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Industries</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Industries</a>
                                    <a href="" onClick={() => { this.onClicked(pages.education) }}>Education</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Education</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad offers a comprehensive platform to bring the best of technology to education domain. A wide range of features and applications make imparting and managing of education a lot smoother while turning the students in great learners.
                        </p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec"  style={{marginTop: '10px'}}>
                                <h3 class="mb-20 title_color">Features</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Live Lecture Capture
                                            <ul class="unordered-list">
                                                <li>Completely Automated System - Right from Recording to Distribution
												</li>
                                                <li>
                                                    Quality Enhancement of the Recordings
												</li>
                                                <li>Multi-view Recording</li>
                                                <li>Anytime-Anywhere Access</li>
                                            </ul>
                                        </li>
                                        <li>Content Management
                                            <ul class="unordered-list">
                                                <li>Build and manage a library of educational content such as - Audio, Video, PDF, etc.</li>
                                                <li>Targeted, controlled, and Secure Distribution of content</li>
                                            </ul>
                                        </li>
                                        <li>Engagement Tools
                                            <ul class="unordered-list">
                                                <li>Bookmarking</li>
                                                <li>Notes Taking</li>
                                                <li>Sharing & Discussion </li>
                                                <li>Question Raising</li>
                                            </ul>
                                        </li>
                                        <li>Assignments
                                            <ul class="unordered-list">
                                                <li>Assignments and student responses can be submitted online.</li>
                                            </ul>
                                        </li>
                                        <li>Time-Table/Organizer
                                            <ul class="unordered-list">
                                                <li>Lecture and test schedules & Organizer</li>
                                            </ul>
                                        </li>
                                        <li>Evaluation
                                            <ul class="unordered-list">
                                                <li>Online test platform</li>
                                                <li>Continuous Evaluation & Feedback platform</li>
                                            </ul>
                                        </li>
                                        <li>School ERP System
                                            <ul class="unordered-list">
                                                <li>A comprehensive ERP tailor-made for school management</li>
                                            </ul>
                                        </li>
                                        <li>Communication
                                            <ul class="unordered-list">
                                                <li>Announcements</li>
                                                <li>Messaging</li>
                                                <li>Discussions</li>
                                            </ul>
                                        </li>
                                        <li>Self-Assessment Tools
                                            <ul class="unordered-list">
                                                <li>Offline tests, analytics and reports for continuous self-assessment & improvement</li>
                                            </ul>
                                        </li>
                                        <li>Analytics & Reports
                                            <ul class="unordered-list">
                                                <li>A set of analytics and reports with customized access levels for administrators, teachers and students </li>
                                            </ul>
                                        </li>
                                        <li>Networking
                                            <ul class="unordered-list">
                                                <li>A networking platform with customized access levels for the students, parents, faculty and administration</li>
                                            </ul>
                                        </li>                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{marginTop:'30px'}}>
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <h3 class="mb-20 title_color">Benefits</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Enhances Learning Efficiency of Students</li>
                                        <li>Anywhere-anytime Learning</li>
                                        <li>More engaging Education Process</li>
                                        <li>Collaborative Learning Environment</li>
                                        <li>Builds a formidable Content-bank</li>
                                        <li>Addresses the core issues faced in teaching-learning process</li>
                                        <li>Continuous Evaluation</li>
                                        <li>Improved Attendance</li>
                                        <li>Improved Attentiveness</li>
                                        <li>More Interactive Lectures</li>
                                        <li>Timely Feedback  for Remedial Actions</li>
                                        <li>Automated attendance recording</li>
                                        <li>The ever evolving set of features and applications helps students with greater involvement, improved retention and enhanced performance</li>
                                        <li>Increases teachers’ efficiency & skills</li>
                                        <li>Comprehensive analytics provide meaningful insights</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Education;

