import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class SoftwarePorductEngineering extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '880px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.softwareProductEngineering) }}>Software Product Engineering</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Software Product Engineering</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Efficiency, faster time to market, excellent customer experience and unhindered evolution are the toughest challenges of businesses. Our software product development services are designed to effectively meet these challenges faced by our customers and partners.
						</p>
                        <div class="row" style={{ marginTop: 30 }}>
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <h3 class="mb-20 title_color">Our Approach</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Design-led engineering</li>
                                        <li>DevOps and CICD led processes</li>
                                        <li>Focus on User Experience</li>
                                        <li>Ability to handle uncertainties</li>
                                        <li>Lean processes, Agile attitude</li>
                                        <li>Partners rather than vendors</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{ marginTop: 30 }}>
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <h3 class="mb-20 title_color">Our Offerings</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Design-led engineering</li>
                                        <li>DevOps and CICD led processes</li>
                                        <li>Focus on User Experience</li>
                                        <li>Ability to handle uncertainties</li>
                                        <li>Lean processes, Agile attitude</li>
                                        <li>Partners rather than vendors</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SoftwarePorductEngineering;