import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class NetworkPlanning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.networkPlanning) }}>Network Planning</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Network Planning</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Network planning has entered a new paradigm. As traffic evolves, the network’s design needs fine-tuning - plugging coverage holes and adding capacity as per changing needs. Moreover, it becomes seminally important that careful planning is carried out, especially when technology layers, including the newer technologies, such as 5G, are expected to work together as a seamless network.
						</p>
                        <p>
                            The key to deploying a cost-effective, high performing network is accurately estimating needs from the network – and the best way to address it. Modern network planning is no longer about the macro-cells alone. Especially with the advent of 5G technologies, in-building coverage, small cells, multi-height coverage and capacity, etc. have started playing a major role in network planning and design.
                        </p>
                        <p>
                            Kanad’s Network Planning services cater to the needs of the MNOs in an extremely cost and time efficient manner.
                        </p>
                        <p>
                            Kanad offers the following Network Planning services.
                        </p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Study of spectrum requirements</li>
                                        <li >Network Design and Dimensioning</li>
                                        <li >Access Network Planning</li>
                                        <li >Transmission and Synchronization plan</li>
                                        <li >Switching Network Topology</li>
                                        <li >Techno-commercial Bid preparations</li>
                                        <li >Identification of coverage pain areas</li>
                                        <li >5G NR planning</li>
                                        <li >Consolidation and swap project planning</li>
                                        <li >LTE-A and m-MIMO planning</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default NetworkPlanning;

