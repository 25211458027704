import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class ExperienceDesign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '780px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.experienceDesign) }}>Experience Design</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Experience Design</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Our experienced architects and visual interface designers leverage the best practices in UX research and design principles. We build captivating user experiences by early validations and keeping users at the center.
						</p>
                        <p>
                            We offer design thinking-based and technology driven services in digital product innovation, customer experience management, and digital transformation. Our service offerings include:
                        </p>
                        <div class="row">
                            <div class="container">
                                <h4>Research</h4>
                                <p>Effective solutions are born from data and insight: we take the time to learn about your goals, ask the right questions to understand your business, empathize with your users, understand your competition and the problems or opportunities that you are seeking to address.</p>
                            </div>
                            <div class="container">
                                <h4>Strategy</h4>
                                <p>Understanding what drives your customers or users and where these overlap with your business goals, help us uncover the ideal path to engagement. We build roadmaps to success that are backed by data-driven user and market research plus with our wealth of experience.</p>
                            </div>
                            <div class="container">
                                <h4>Experience Design</h4>
                                <p>Great design is essential to business. Our design-thinking lead process permeates our organization and culture. Our designers converge their design and business skills with your industry insight to create simple and memorable experiences for your users and customers.</p>
                            </div>
                            <div class="container">
                                <h4>Development</h4>
                                <p>Through communication, collaboration and transparency, our flexible and agile process guides the projects from definition through developing and executing without a hitch. Mobile to enterprise-scale apps, our work is always built for reliability, flexibility, and scale.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ExperienceDesign;