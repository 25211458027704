import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class RFSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.networkOptimization) }}>RF Survey</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">RF Survey</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad offers comprehensive RF Survey services and covers the following aspects -
						</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >
                                            Coverage requirements
                                            <p>Network type, coverage, signal quality, the number of users, covering solution selection                                                
                                            </p>
                                        </li>
                                        <li >
                                            On-site environment
                                            <p>Floor plan of building: structures, signal blocking situation, laying points of AP / switch / antenna and other equipment, wiring and length, signal blind spot, hot spot.
                                            System topology: equipment & feeder specs, link calculation, power supply, etc
                                            </p>
                                        </li>
                                        <li >
                                            Coverage solution design
                                            <p>According to customer requirements and pre-survey data to work out the detailed coverage solution: coverage for each building room, equipment type, quantity, configuration, laying points, installation, etc.</p>
                                        </li>
                                        <li>
                                            Post-construction inspection
                                            <p>To test whether the signal coverage, strength and data traffic have satisfied the requirements</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default RFSurvey;