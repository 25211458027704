import React from 'react';
import Team from '../Home/team';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
	}

    render() {
        return (
            <div>                
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>About Us</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a href="" onClick={() => { this.onClicked(pages.about) }}>About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="mission_area">
                    <div class="row m0">
                        <div class="col-lg-6 p0">
                            <div class="left_img"><img src="/kanadnetworks/img/other/KanadNetworks_Telecom.jpg" alt="" style={{ width: '100%' }} /></div>
                        </div>
                        <div class="col-lg-6 p0">
                            <div id="at-products">
                                <h4 style={{ marginLeft: '20px' }} >About the Company!</h4>
                                <p style={{ marginLeft: '20px', fontWeight: 'bold' }}>Kanad Networks</p>
                                <p style={{ marginLeft: '20px', fontWeight: 'bold' }}>…Crafting  a digital symphony!</p>
                                <p style={{ marginLeft: '20px' }}>We are a technology start-up offering innovative products, solutions and services to a range of industries and domains. Kanad started off with venturing into telecom engineering and has gradually sprouted into various domains</p>
                                <p style={{ marginLeft: '20px' }}>Headquartered in Delhi, Kanad was founded by a group of IIT and Wharton alumni. Within a short span, it has built a formidable team and carved out a space for itself.</p>

                            </div>
                        </div>
                    </div>
                </section>
                {/* <section class="team_area" id="at-team">
                    <Team></Team>
                </section> */}
            </div>
        );

    }
}

export default About;

