import React from 'react';
import { Route, Router } from 'react-router';
import { pages } from './constants/url';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './views/Home';
import NotFound from './views/NotFound';
import About from './views/About';
import Contact from './views/Contact';
//Products
import Quark from './views/Products/quark';
import TracerDrive from './views/Products/tracerdrive';
import Tracer from './views/Products/tracer';
import Fission from './views/Products/fission';
import Lattice from './views/Products/lattice';
//Services
import NetworkPlanning from './views/Services/networkplanning';
import NetworkOptimization from './views/Services/networkoptimization';
import AutomaticFrequencyPlanning from './views/Services/automaticfrequencyplanning';
import NetworkIntegration from './views/Services/networkIntegration';
import SCFTOptimization from './views/Services/scftOptimization';
import OpenRan from './views/Services/openran';
import FiveGServices from './views/Services/fivegservices';
import SpectrumRefarmingServices from './views/Services/spectrumrefarmingservices';
import RFSurvey from './views/Services/rfsurvey';
import EMFSurvey from './views/Services/emfsurvey';
//Digital Services
import SoftwarePorductEngineering from './views/DigitalServices/softwareproductengineering';
import BusinessTransformation from './views/DigitalServices/businesstransformation';
import DataEngineering from './views/DigitalServices/dataengineering';
import Analytics from './views/DigitalServices/analytics';
import MobileTechnologies from './views/DigitalServices/mobiletechnologies';
import CloudServices from './views/DigitalServices/cloudservices';
import BlockchainDevServices from './views/DigitalServices/blockchaindevservices';
import ExperienceDesign from './views/DigitalServices/experiencedesign';
import TeamConsulting from './views/DigitalServices/teamcunsulting';

//Industries
import ECommerce from './views/Industries/ecommerce';
import Education from './views/Industries/education';
import Finance from './views/Industries/finance';
import RealEstate from './views/Industries/realestate';
import Manufacturing from './views/Industries/manufacturing';

//Liquor
import LiquorDetails from './views/Liquor/liquordetails';

class Routes extends React.Component {
	render() {
		return (
			<div>
				<Header />
				<Router {...this.props}>
					<Route exact path={pages.home} component={Home} />
					<Route exact path={pages.about} component={About} />
					<Route exact path={pages.contact} component={Contact} />

					{/* Products */}
					<Route path={pages.quark} component={Quark} />
					<Route path={pages.tracerDrive} component={TracerDrive} />
					<Route path={pages.tracer} component={Tracer} />
					<Route path={pages.fission} component={Fission} />
					<Route path={pages.lattice} component={Lattice} />

					{/* Services */}
					<Route path={pages.networkPlanning} component={NetworkPlanning} />
					<Route path={pages.networkOptimization} component={NetworkOptimization} />
					<Route path={pages.automaticFrequencyPlanning} component={AutomaticFrequencyPlanning} />
					<Route path={pages.networkIntegration} component={NetworkIntegration} />
					<Route path={pages.scftOptimization} component={SCFTOptimization} />
					<Route path={pages.rfSurvey} component={RFSurvey} />
					<Route path={pages.emfSurvey} component={EMFSurvey} />
					<Route path={pages.openRan} component={OpenRan} />
					<Route path={pages.fiveGServices} component={FiveGServices} />
					<Route path={pages.spectrumRefarmingServices} component={SpectrumRefarmingServices} />

					{/* Digital Services */}
					<Route path={pages.softwareProductEngineering} component={SoftwarePorductEngineering} />
					<Route path={pages.businessDigitalTransformation} component={BusinessTransformation} />
					<Route path={pages.dataEngineering} component={DataEngineering} />
					<Route path={pages.analytics} component={Analytics} />
					<Route path={pages.mobileTechnologies} component={MobileTechnologies} />
					<Route path={pages.cloudServices} component={CloudServices} />
					<Route path={pages.blockchainServices} component={BlockchainDevServices} />
					<Route path={pages.experienceDesign} component={ExperienceDesign} />
					<Route path={pages.teamConsulting} component={TeamConsulting} />

					{/* Industries */}
					<Route path={pages.eCommerce} component={ECommerce} />
					<Route path={pages.education} component={Education} />
					<Route path={pages.finance} component={Finance} />
					<Route path={pages.manufacturing} component={Manufacturing} />
					<Route path={pages.realEstate} component={RealEstate} />

					{/* Liquor */}
					<Route path={pages.liquorDetails} component={LiquorDetails} />

					<Route path={pages.all} component={NotFound} />
					<Route path={pages.error} component={NotFound} />
				</Router>
				<Footer />
			</div>
		)
	}
}

export default Routes;