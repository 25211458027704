import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class SpectrumRefarmingServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '770px' }}>
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.spectrumRefarmingServices) }}>Spectrum Refarming Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Spectrum Refarming Services</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad uses its comprehensive suite of proprietary solutions to address challenges faced in a successful Spectrum Refarming.  Kanad helps its customers achieve a smooth transition to a changed spectrum scenario.
						</p>
                        <p>
                            Kanad’s Spectrum Refarming Services take care of all stages of the overall project, including -
                        </p>

                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Traffic Recording based Heat map generation</li>
                                        <li >Feasibility Study/Scenario Analysis</li>
                                        <li >Network Dimensioning/TRX Dimensioning </li>
                                        <li >Site Addition/Deletion</li>
                                        <li >TRX Addition/Deletion</li>
                                        <li >Frequency/Neighbor Planning</li>
                                        <li >Post Refarming Parametric Optimization including Traffic Balancing/Offloading</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SpectrumRefarmingServices;