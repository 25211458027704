const config = {
    iCenter: {
        lng: 77.037880,
        lat: 28.424970
    },
    mapZoom: 17,
    data: {
        lng: 77.037880,
        lat: 28.424970,
        address: '816, Vipul Business Park, Sohna Road, Sector 48',
        location: '',
        city: 'Gurugram',
        state: 'Haryana',
        pin: '122004',
        country: 'India'
    },
    keys: {       
        googleMap: 'AIzaSyAqEV3aSCQY2vSKyNV6Wc-NYPnkck5f8sI',//'AIzaSyDqg8RlVg0ROy9va4VsHmgJx_T92U3rRXw',
    }
}

export { config };