import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class AutomaticFrequencyPlanning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{maxWidth:'755px'}}>
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.automaticFrequencyPlanning) }}>Automatic Frequency Planning</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Automatic Frequency/PSC/PCI Planning</h3>                        
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            A comprehensive suite of proprietary solutions and deep expertise in radio engineering enables Kanad to offer its partners and customers efficient and effective services for automatic planning for Frequency, PSC, PCI and Neighbor list.
						</p>
                        <p>
                            This offering from Kanad has consistently resulted in optimum plan with remarkable performance in the field.
                        </p>
                        <p>
                            In addition to being extremely cost effective, this service is also significantly quick.
                        </p>
                    </div>
                </section>
            </div>
        );
    }
}

export default AutomaticFrequencyPlanning;