import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class DataEngineering extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '780px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.dataEngineering) }}>Data Engineering</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Data Engineering</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Aligning Data and Artificial Intelligence strategies with business objectives, irrespective of data volumes, variety and velocity.
						</p>
                        <p>Harnessing the power of big data analytics to grow business, improve profitability, and enhance customer experience</p>
                        <div class="row" >
                            <div class="container" style={{ marginTop: '20px' }}>
                                <h3 class="mb-20 title_color">Business Challenges</h3>
                                <h4>Data Volume, Variety and Velocity</h4>
                                <p>Data hoarding is a huge challenge. Having the right blend of technology and process to manage massive data-sets is an uphill task in fast-changing technology landscape.</p>
                            </div>
                            <div class="container">
                                <h4>Actionable insights and recommendations, at desirable speed</h4>
                                <p>It is critical for businesses to not only measure, but to also gain insights and directions. And all this needs to happen near real-time.</p>
                            </div>
                            <div class="container">
                                <h4>Data democratization</h4>
                                <p>Organizations face challenges in assembling data from multiple sources and ensuring the data reaches the right audience. Data silos obstruct this flow from raw data to informed decision.</p>
                            </div>

                            <div class="container" style={{ marginTop: 30 }}>
                                <h3 class="mb-20 title_color">Capabilities</h3>
                                <h4>Technology Agnostic Approach</h4>
                                <p>Capabilities across the data and cloud tech stack enable ingestion through transformation and consumption</p>
                            </div>
                            <div class="container">
                                <h4>Holistic Data Management and Governance</h4>
                                <p>Data practices and solutions across different data stages</p>
                                <p>Alignment around definitions and access</p>
                            </div>
                            <div class="container">
                                <h4>Smart Data Usage</h4>
                                <p>Focusing on the right data ecosystem as opposed to an overly complex architecture</p>
                                <p>Aligning with business priorities </p>
                                <p>Best-in-class consulting services</p>
                            </div>
                            <div class="container" >
                                <h4>Business Impact</h4>
                                <p>Enhanced ROI with industry-tested data strategy framework, which aligns Big Data management with business objectives</p>
                                <p>Higher adoption rate of insights with a centralized governance process and a self-service advanced analytics platform</p>
                                <p>Rapid deployments with a cost-effective approach by implementing industry-trending in-house and cloud serverless technology</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default DataEngineering;