import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class OpenRan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content">
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.openRan) }}>OpenRAN</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">OpenRAN</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                        OpenRAN promises an alternative to the legacy radio access network (RAN), arguably the costliest parts of the infrastructure. In traditional RAN, hardware components and associated software are tightly coupled, and interfaces do not support interoperability between different vendors. That means nearly all the equipment comes from only one supplier. With the advent of  OpenRAN, operators can use software-based network functions on standard (COTS) servers. Open interfaces allow a new freedom – the use of one supplier’s radios with another’s processors; thus revolutionizing network builds.
						</p>
                        <p>
                        Kanad offers the following services for OpenRAN networks
                        </p>
                        
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Integration and Commissioning Cloud BTS</li>
                                        <li >Integration and Commissioning Cloud RNC</li>
                                        <li >Integration and Commissioning Cloud BSC</li>
                                        <li >Integration and Commissioning Wi-Fi Controller</li>
                                        <li >Integration and Commissioning Cloud Core</li>
                                        <li >Benchmarking</li>
                                        <li >RF Drive test</li>
                                        <li >RF Planning & Optimization</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default OpenRan;