import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class NetworkOptimization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '680px' }}>
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.networkOptimization) }}>Network Optimization</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Network Optimization</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad’s extensive expertise on wireless network engineering offers it a uniquely positioned opportunity to offer cost-effective services to optimize the Radio Access Networks.
						</p>
                        <p>
                            Kanad, empowered by its comprehensive suite of proprietary optimization solutions, offers RAN optimization Services to the wireless operators. This offering from Kanad has consistently given remarkable performance improvement in the field.
                        </p>
                        <p>
                            In addition to being extremely cost effective, this service is also significantly quick. The entire cycle of optimization for a full sized network can be completed within a fortnight or a month.
                        </p>
                        <p>
                            To achieve significant improvements in the network quality, Kanad carries out the following activities –
                        </p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Cell Database Parameter Optimization</li>
                                        <li >Frequency/PSC/PCI fine-tuning</li>
                                        <li >Neighbor list Optimization</li>
                                        <li >Parameter Configuration Consistency Check</li>
                                        <li >Traffic and throughput analysis</li>
                                        <li >Parameter planning and optimization</li>
                                        <li >Dropped call analysis</li>
                                        <li >Handover success analysis</li>
                                        <li >Drive test analysis</li>
                                        <li >Interference analysis</li>

                                        <li >Field measurements and acceptance testing</li>
                                        <li >Frequency & Strategy Planning</li>
                                        <li >Top N Site Targeting</li>
                                        <li >Worst cell analysis</li>
                                        <li >VoLTE performance analysis</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default NetworkOptimization;