import React, { Component } from 'react'
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

export default class Industries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {

        return (
            <section class="team_area" id="at-industries">
                <div class="row m0">
                    <div class="col-lg-4 col-md-6 p0">
                        <div class="project_item">
                            <img src="/kanadnetworks/img/industries/KanadNetworks_Education.jpg" alt="" />
                            <div class="hover_text">
                                <h4>Education</h4>
                                <div class="cat">
                                    <a href="">Industries</a>
                                </div>
                                <a class="main_btn"href="" onClick={() => { this.onClicked(pages.education) }}>View More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 p0">
                        <div class="project_item">
                            <img src="/kanadnetworks/img/industries/KanadNetworks_Finance.jpg" alt="" />
                            <div class="hover_text">
                                <h4>Finance</h4>
                                <div class="cat">
                                    <a href="">Industries</a>                                    
                                </div>
                                <a class="main_btn"href="" onClick={() => { this.onClicked(pages.finance) }}>View More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 p0">
                        <div class="project_item">
                            <img src="/kanadnetworks/img/industries/KanadNetworks_Real Estate.jpg" alt="" />
                            <div class="hover_text">
                                <h4 >Real Estate</h4>
                                <div class="cat">
                                    <a href="">Industries</a>                                   
                                </div>
                                <a class="main_btn" href="" onClick={() => { this.onClicked(pages.realEstate) }}>View More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 p0">
                        <div class="project_item">
                            <img src="/kanadnetworks/img/industries/KanadNetworks_Manufacturing.jpg" alt="" />
                            <div class="hover_text">
                                <h4>Manufacturing</h4>
                                <div class="cat">
                                    <a href="">Industries</a>
                                </div>
                                <a class="main_btn" href="" onClick={() => { this.onClicked(pages.manufacturing) }}>View More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 p0">
                        <div class="project_item">
                            <img src="/kanadnetworks/img/industries/KanadNetworks_eCommerce.jpg" alt="" />
                            <div class="hover_text">
                                <h4 >eCommerce</h4>
                                <div class="cat">
                                    <a href="">Industries</a>
                                </div>
                                <a class="main_btn" href="" onClick={() => { this.onClicked(pages.eCommerce) }}>View More</a>
                            </div>
                        </div>
                    </div>                    
                </div>
            </section>
        )
    }
}