import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import GoogleMaps from './googlemaps';
import $ from 'jquery';
import Snackbar from '@material-ui/core/Snackbar';
import validator from 'validator';
import API from '../../api';
import { postUrl } from '../../constants/url';
import Loader from '../../components/Loader';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: 'Send Message',
            googlemapss: [],
            errors: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            errMessage: '',
            open: false,
            data: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })

        let lst = [];
        lst.push(<GoogleMaps></GoogleMaps>);
        this.setState({ googlemapss: lst })
    }

    msgHide() {
        this.setState({ errMessage: '', open: false })
    }

    nameChange(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.name = '';
        data.name = value;
        this.setState({
            data, errors
        });
    }

    emailChange(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.email = validator.isEmail(value) ? '' : 'Incorrect Email';
        data.email = value;
        this.setState({
            data, errors
        });
    }
    subjectChange(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.subject = '';
        data.subject = value;
        this.setState({
            data, errors
        });
    }
    messageChange(event) {
        const { target: { value } } = event;
        const data = this.state.data;
        const errors = this.state.errors;
        errors.message = '';
        data.message = value;
        this.setState({
            data, errors
        });
    }

    verify() {
        const errors = this.state.errors;
        let ver = true;
        if (this.state.data.name == '') {
            errors.name = 'Name is mandatory';
            ver = false;
        }
        if (this.state.errors.name != '') {
            ver = false;
        }

        if (this.state.data.email == '') {
            errors.email = 'Email address is mandatory';
            ver = false;
        }
        if (this.state.errors.email != '') {
            ver = false;
        }

        if (this.state.data.subject == '') {
            errors.subject = 'Subject is mandatory';
            ver = false;
        }
        if (this.state.errors.subject != '') {
            ver = false;
        }

        if (this.state.data.message == '') {
            errors.message = 'Message is mandatory';
            ver = false;
        }
        if (this.state.errors.message != '') {
            ver = false;
        }

        this.setState({ errors });
        return ver;
    }

    submit() {
        if (this.verify()) {
            this.setState({ submit: <Loader /> })
            API.post(postUrl.contactUsEmail, this.state.data)
                .then(res => {
                    if (res.data.status === 200) {
                        let data = {
                            name: '',
                            email: '',
                            subject: '',
                            message: ''
                        }
                        this.setState({
                            open: true,
                            errMessage: res.data.error,
                            data
                        })
                    }
                    else {
                        this.setState({
                            open: true,
                            errMessage: res.data.error
                        })
                    }
                    this.setState({ submit: 'Send Message' });
                }).catch(err => {
                    this.setState({
                        open: true,
                        errMessage: err
                    })
                    this.setState({ submit: 'Send Message' });
                });
        }
    }

    render() {
        return (
            <div>
                <section className="banner_area">
                    <div className="banner_inner d-flex align-items-center">
                        <div className="container">
                            <div className="banner_content">
                                <h2>Contact Us</h2>
                                <div className="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a href="" onClick={() => { this.onClicked(pages.contact) }}>Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact_area p_120">
                    <div className="container">
                        <div id="mapBox" className="mapBox">
                            {this.state.googlemapss}
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <div className="contact_info">
                                    <div className="info_item">
                                        <i className="lnr lnr-home"></i>
                                        <h6>Gurugram, Haryana, 122004, India</h6>
                                        <p>816, Vipul Business Park, Sohna Road, Sector 48</p>
                                    </div>
                                    <div class="info_item">
                                        <i class="lnr lnr-phone-handset"></i>
                                        <h6><a href="#">(0124) 4085 843</a></h6>
                                        <p>Mon to Fri 9 am to 6 pm</p>
                                    </div>
                                    <div class="info_item">
                                        <i class="lnr lnr-envelope"></i>
                                        <h6><a href="#">info@kanadnetworks.com</a></h6>
                                        <p>Send us your query anytime!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row contact_form">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="name" name="name" value={this.state.data.name} onChange={e => this.nameChange(e)} placeholder="Enter your name" />
                                            <p>{this.state.errors.name}</p>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" id="email" name="email" value={this.state.data.email} onChange={e => this.emailChange(e)} placeholder="Enter email address" />
                                            <p>{this.state.errors.email}</p>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="subject" name="subject" value={this.state.data.subject} onChange={e => this.subjectChange(e)} placeholder="Enter Subject" />
                                            <p>{this.state.errors.subject}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <textarea className="form-control" name="message" id="message" rows="1" value={this.state.data.message} onChange={e => this.messageChange(e)} placeholder="Enter Message"></textarea>
                                            <p>{this.state.errors.message}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <button type="submit" value="submit" className="btn submit_btn" onClick={() => this.submit()}>{this.state.submit}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    onClose={this.msgHide}
                    autoHideDuration={5000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.errMessage}</span>}
                />
            </div>
        );

    }
}

export default Contact;

