import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Analytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '720px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.analytics) }}>Analytics</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Analytics</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Kanad helps in addressing your greatest challenges with solutions that unlock the potential in data. Leveraging the transformative power of analytics, we can deliver insights that change how you make decisions, re-engineer or automate business processes, and effectively empower you to capture growth.
						</p>
                        <div class="row">
                            <div class="container" style={{ marginTop: '20px' }}>
                                <h3 class="mb-20 title_color">The benefits</h3>
                                <p>Improved and Accelerated decision-making</p>
                                <p>Increased competitiveness of your core deliveries</p>
                                <p>Spring new businesses around data and analytics</p>
                            </div>
                            <div class="container" style={{ marginTop: '20px' }}>
                                <h3 class="mb-20 title_color">Services</h3>
                                <p>The analytics consulting capabilities are designed to help you anticipate, predict and transform your business into new markets and expand growth opportunities. Analytics services include:</p>

                                <ul class="unordered-list">
                                    <li>Insights, forecasting and visualization</li>
                                    <li>Asset-based Analytics-as-a-Service</li>
                                    <li>Advanced analytics and data strategy</li>
                                    <li>Demand forecasting and supply chain optimization</li>
                                    <li>Talent and HR analytics</li>
                                    <li>Fraud and risk detection</li>
                                    <li>Asset placement strategy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Analytics;