import React, { Component } from 'react'
import ItemsCarousel from 'react-items-carousel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';

export default class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }} id="services-ItemsCarousel">
                <ItemsCarousel
                    requestToChangeActive={(v) => {
                        this.setState({ index: v })
                    }}
                    activeItemIndex={this.state.index}
                    numberOfCards={1}
                    gutter={20}
                    infiniteLoop={true}
                    disableSwipe={false}
                    slidesToScroll={1}
                    leftChevron={<IconButton size='small' aria-label="add"><ArrowBackIosIcon /></IconButton>}
                    rightChevron={<IconButton size='small' aria-label="add"><ArrowForwardIosIcon /></IconButton>}
                    outsideChevron
                    chevronWidth={40}
                >
                    {
                        services.map(val => {
                            return (
                                <div class="mission_text" key={val.name} style={{ cursor: 'pointer', textAlign: 'center' }}>
                                    <h4>{val.name}</h4>
                                    <p>{val.desc}</p>

                                    <a class="banner_btn" style={{ marginTop: '30px' }} href="" onClick={() => { this.onClicked(val.page) }}>View more</a>
                                </div>
                            )
                        })
                    }
                </ItemsCarousel>
            </div>
        )
    }
}

const services = [
    { name: "Network Planning", page: pages.networkPlanning, type: '', src: "", desc: 'Comprehensive planning services of mobile networks across technologies and OEMs' },
    { name: "Network Optimization", page: pages.networkOptimization, type: '', src: "", desc: 'Effective and efficient optimization services for mobile networks across technologies and OEMs.' },
    { name: "Automatic Frequency/PSC/PCI Planning", page: pages.automaticFrequencyPlanning, type: '', src: "", desc: 'OSS data based frequency/PSC/PCI/Neighbor list planning services for mobile networks across OEMs' },
    { name: "Network Installation, Commissioning, Configuration and Integration", page: pages.networkIntegration, type: '', src: "", desc: 'End-to-end network rollout services for mobile networks across technologies and OEMs' },
    { name: "SCFT/Cluster Optimization", page: pages.scftOptimization, type: '', src: "", desc: 'Cost-effective SCFT/cluster optimization services with a quick turn around time for mobile networks across technologies' },
    { name: "RF Survey", page: pages.rfSurvey, type: '', src: "", desc: 'A comprehensive and semi-automated RF Survey services for mobile networks' },
    { name: "EMF Survey", page: pages.emfSurvey, type: '', src: "", desc: 'A comprehensive and semi/fully-automated EMF Survey services for mobile networks' },
    { name: "OpenRAN", page: pages.openRan, type: '', src: "", desc: 'With the advent of  OpenRAN, operators can use software-based network functions on standard (COTS) servers. Open interfaces allow a new freedom – the use of one supplier’s radios with another’s processors; thus revolutionizing network builds.' },
    { name: "5G Services", page: pages.fiveGServices, type: '', src: "", desc: 'Key Focus on M2M/IoT using 3GPP NB-IoT, Implementing NB-IoT based Solutions,NB-IoT Planning Services: Coverage Planning, Capacity & Link Budgets for Cat-M1 (eMTC) & Cat-NB1 (NB-IoT).' },
    { name: "Spectrum Refarming Services", page: pages.spectrumRefarmingServices, type: '', src: "", desc: 'Kanad uses its comprehensive suite of proprietary solutions to address challenges faced in a successful Spectrum Refarming.  Kanad helps its customers achieve a smooth transition to a changed spectrum scenario.' }];