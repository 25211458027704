import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class SCFTOptimization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    
    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '710px' }}>
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.scftOptimization) }}>SCFT/Cluster Optimization</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">SCFT/Cluster Optimization</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Utilizing our suite of proprietary solutions along with industry standard tools, Kanad offers a comprehensive set of services -
						</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <div class="">
                                    <ul class="unordered-list">
                                        <li >Site Shake Down (SSD) – Verify Swap sectors, TX Imbalances, Intra & Inter HO and Data Throughput testing</li>
                                        <li >Single/Multiple Site Verification – (SSV/MSV) – Verify coverage, accessibility, retainability, etc.</li>
                                        <li >Cluster/Market Drive – Optimization drives to access Drop, Mobility, Throughput, Latency, CSFB/VoLTE Performance, accessibility, retainability, etc.</li>
                                        <li >VoLTE Drive Testing – To assess quality of VoLTE calls</li>
                                        <li >Benchmarking Drive Testing – To assess network competitiveness</li>
                                        <li >Spot Coverage Checks – To troubleshoot customer complaints </li>
                                        <li >Scanner Drive Testing – To assess coverage</li>
                                        <li >Continuous Wave (CW) Drive Testing – To perform model tuning</li>
                                        <li >Indoor Walk Testing – To verify iDAS/oDAS/small cells functionality</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SCFTOptimization;