import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class Quark extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	onClicked(page) {
		browserHistory.push({
			pathname: page
		})
	}

	componentDidMount() {
		$('#loader').css({
			display: 'none'
		})
	}

	render() {
		return (
			<div id="at-products-detail">
				<section class="banner_area">
					<div class="banner_inner d-flex align-items-center">
						<div class="container">
							<div class="banner_content">
								<h2>Products</h2>
								<div class="page_link">
									<a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
									<a>Products</a>
									<a href="" onClick={() => { this.onClicked(pages.quark) }}>Quark</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class="whole-wrap">
					<div class="container">
						<h3 class="text-heading title_color" >Parametric Optimization Tool</h3>
						<div class="section-top-border">
							<div class="row">
								<div class="col-md-3">
									<img src="/kanadnetworks/img/products/kanadnetworks_quark.png" alt="" class="img-fluid" />
								</div>
								<div class="col-md-9 mt-sm-20 left-align-p">
									<p >
										In order to survive in an increasingly competitive environment, operators must focus on improving operational efficiency and minimizing capital expenditure, while at the same time delivering the highest quality end user experience.
						</p>
									<p >
										Past couples of decades have witnessed huge growth in mobile networks, resulting in massive rollout of cell sites, capacity expansions, dual band introduction etc. but mostly keeping the existing network intact in terms of physical site parameters as well as cell database parameters causing adverse impact on the network quality.
						</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class="sample-text-area">
					<div class="container">
						<p>
							Moreover most of the cells are configured with default or at least identical parameter setting across the Network, for a significant set of parameters. That’s where we see an opportunity for significant value addition by fine-tuning/optimizing the parameter settings for each individual cell based on geography, traffic, coverage area and neighbor relations.
						</p>
						<p>Kanad’s Quark is a multi-vendor and multi-technology automated solution that dynamically optimizes key cell database parameters at sector level, thereby boosting system performance while minimizing CapEx and OpEx.</p>
						<p>By providing Quark with network configuration and statistics from the OSS, it is possible to automatically fine tune the performance that directly improves the end user experience. Quark has consistently given remarkable performance improvement in the field.</p>
						<p>Quark is available for GSM, UMTS and LTE networks, including the interworking between these systems. Our team’s extensive competence and hands on experience has been leveraged for the design and continuous enhancement of our tool, which boost system performance in terms of capacity, quality & coverage.</p>

						<p>Quark Optimizer requires readily available input data collected by operaors OSS systems on a regular basis. Also, the recommendations generated are essesntially soft-changes which can be implemented from the network center (or remotely), without requiring any field visits. Therefore, entire network can be optimized very quickly and in an extremely cost effective manner.</p>
						<p>Quark has been generating tremendous customer interest. We have already won a number of services contracts with tier 1 operators and have been producing excellent results. </p>				</div>
				</section>
				<div class="whole-wrap">
					<div class="container">
						<div class="row">
							<div class="container">
								<h3 class="mb-20 title_color">Areas of Focus</h3>
								<div class="">
									<ul class="unordered-list">
										<li>Quality of Service</li>
										<li>Handover Performance, including iRAT handovers</li>
										<li>Automatic Traffic Redistribution</li>
										<li>Spectral Efficiency</li>
										<li>Dual Band Traffic Balancing</li>
										<li>Idle Mode Performance</li>
										<li>Efficiency of Macro and Micro Layers</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="container" style={{ marginTop: '20px' }}>
								<h3 class="mb-20 title_color">Benefits</h3>
								<div class="">
									<ul class="unordered-list">
										<li>Fta Keys</li>
										<li>For Women Only Your Computer Usage</li>
										<li>Enhanced network performance
											<ul class="unordered-list">
												<li>Reduction in Call Drops ~ 10% – 25%
												</li>
												<li>
													Reduction in Handover Failures ~ 10% – 25%
												</li>
												<li>Improvement in Voice Quality (UL/DL) ~ 10% – 20%</li>
											</ul>
										</li>
										<li>Significant CapEx reduction by ensuring improved utilization of existing network resources</li>
										<li>Dramatic OpEx reduction through automation</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Quark;

