import React, { Component } from 'react';

class Login extends Component {
  render() {
    return (
      <div>
        <div class="at-haslayout at-innerbannerholder">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-12">
                <div class="at-innerbannercontent">
                  <div class="at-title"><h2>Page Lost In the Sea</h2></div>
                  <ol class="at-breadcrumb">
                    <li><a href="index-2.html">Main</a></li>
                    <li>404 Error</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main id="at-main" class="at-main at-haslayout">
          <div class="at-haslayout at-main-section">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-12 push-md-0 col-lg-10 push-lg-1 col-xl-8 push-xl-2">
                  <div class="at-error">
                    <figure class="at-error-img"><img src="/public/images/404.gif" alt="404 img" /></figure>
                    <div class="at-title">
                      <h3>Ooops! This Page is No Longer Available</h3>
                    </div>
                    <div class="at-description">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempoer incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nrud exercitation ullamco laboris nisi ut aliquip.</p>
                    </div>
                    <div class="at-btnarea">
                      <a href="/" class="at-btn">Back To Homepage</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Login;
