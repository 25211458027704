import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class NetworkIntegration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }
    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-products-detail">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '1025px' }}>
                                <h2>Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.networkIntegration) }}>Network Installation, Commissioning, Configuration and Integration</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Network Installation, Commissioning, Configuration and Integration</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p >
                            Our team of experienced and trained Field Engineering Services Professionals; backed by multi-technology, multi-vendor product expert NIC/NOC has enabled Kanad to assist our Partners to deploy advanced technology networks in an optimal and cost effective manner. With exhaustive capabilities in Provisioning, Installation, Commissioning, Integration, O&M, Troubleshooting, Alarm/NEA testing along with cutting edge in-house Product suite provide a competitive edge.
						</p>
                        <img src="/kanadnetworks/img/services/kanadnetworks_networkintegration.png" alt="" class="img-fluid" style={{ marginTop: '50px', marginBottom: '50px' }} />
                    </div>
                </section>
            </div>
        );
    }
}

export default NetworkIntegration;