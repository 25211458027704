import React from 'react';
import { pages } from '../../constants/url';
import { browserHistory } from 'react-router';
import $ from 'jquery';

class TeamConsulting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        $('#loader').css({
            display: 'none'
        })
    }

    onClicked(page) {
        browserHistory.push({
            pathname: page
        })
    }

    render() {
        return (
            <div id="at-digitsl-services">
                <section class="banner_area">
                    <div class="banner_inner d-flex align-items-center">
                        <div class="container">
                            <div class="banner_content" style={{ maxWidth: '900px' }}>
                                <h2>Digital Services</h2>
                                <div class="page_link">
                                    <a href="" onClick={() => { this.onClicked(pages.home) }}>Home</a>
                                    <a>Digital Services</a>
                                    <a href="" onClick={() => { this.onClicked(pages.teamConsulting) }}>Team Augmentation & Consulting</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="whole-wrap">
                    <div class="container">
                        <h3 class="text-heading title_color">Team Augmentation & Consulting</h3>
                    </div>
                </div>
                <section class="sample-text-area">
                    <div class="container">
                        <p>
                            Kanad offers development firepower and implementation best practices that help you focus on growing and managing your business while we expand your technology offerings and/or improve your processes
						</p>
                        <div class="row" style={{ marginTop: 30 }}>
                            <div class="col-lg-4 col-sm-6 mt-sm-30 typo-sec">
                                <h3 class="mb-20 title_color">Our Strengths</h3>
                                <div class="">
                                    <ul class="unordered-list">
                                        <li>Cross-functional team</li>
                                        <li>On-Demand Engagement</li>
                                        <li>Flexibility and Control</li>
                                        <li>Readily and exclusively available IT Infrastructure </li>
                                        <li>Access to Leading Technologies</li>
                                        <li>Complete transparency and control</li>
                                        <li>Extensive platform understanding of E-Commerce,  Fintech, EdTech. Digital Payments, Machine Learning, Telecom</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default TeamConsulting;